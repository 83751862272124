import { Settings } from './interfaces/settings';
import { Target } from './interfaces/target';

export class KswElementHandler {
  private window: any;

  private anchor: HTMLElement;

  private element: HTMLIFrameElement | undefined;

  private settings: Settings;

  private target: Target;

  public htmlContent?: string;

  constructor(
    private _window: any,
    private anchorName: string,
    private _target: Target,
    private _settings: Settings,
  ) {
    this.window = _window;
    this.anchor = this.window.document.getElementById(anchorName);
    this.target = _target;
    this.settings = _settings;
  }

  boot(): void {
    if (this.element) {
      this.shutdown();
    }

    if (!this.anchor) {
      console.error("Anchor element for KG search widget couldn't be found");
      return;
    }

    this.element = document.createElement('iframe');
    this.element.id = 'onlim-ksw';
    this.element.className = 'onlim-' + this.settings.widgetName;
    this.element.dataset.targetSettings = this.target.settings;
    this.element.dataset.targetApi = this.target.api;

    this.anchor.appendChild(this.element);

    const baseUrl = this.getOrigin();
    if (!baseUrl) {
      console.error(
        "Script element for booting KG search widget couldn't be found",
      );
      return;
    }

    if (!this.htmlContent) {
      console.error('Html Content is missing');
      return;
    }

    this.htmlContent = this.htmlContent.replace(
      '<base href="/ksw',
      '<base href="' + baseUrl + '/ksw',
    );

    const iframeDocument = (this.element.contentWindow as Window).document;
    iframeDocument.open();
    iframeDocument.write(this.htmlContent);
    iframeDocument.close();
  }

  shutdown(): void {
    const elementIds = ['onlim-ksw'];
    elementIds.forEach((elementId) => {
      const elements = document.querySelectorAll(
        '#' + elementId + '.onlim-' + this.settings.widgetName,
      );
      elements.forEach((element) => {
        (element.parentNode as HTMLElement).removeChild(element);
      });
    });
    this.element = undefined;
  }

  setStyles(): void {
    if (!this.anchor) {
      return;
    }

    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const css = require('./styles.css');
    const style: any = document.createElement('style');
    this.anchor.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
  }

  private getOriginByDataAttribute(): string | undefined {
    const script: HTMLScriptElement | null = document.querySelector(
      'script[data-script-id="onlim-ksw"]',
    );
    if (!script) {
      return undefined;
    }

    const htmlAnchorElement: HTMLAnchorElement = document.createElement('a');

    htmlAnchorElement.href = script.src;

    return htmlAnchorElement.origin;
  }

  // This is used as a fallback as initial the data attribute wasn't set, check once deployed in premium if bayern could adapt this on there side
  private getOriginByScriptSrc(): string | undefined {
    const scripts = document.getElementsByTagName('script');
    const scriptsList: HTMLScriptElement[] =
      Array.prototype.slice.call(scripts);

    let match: string | undefined;
    const regex = new RegExp('/ksw/booter.js$');
    scriptsList.forEach((script) => {
      const { src } = script;
      if (regex.test(src)) {
        match = src;
      }
    });

    if (match) {
      const kswScriptUrl = document.createElement('a');
      kswScriptUrl.href = match;
      return kswScriptUrl.origin;
    }
    return undefined;
  }

  private getOrigin(): string | undefined {
    return this.getOriginByDataAttribute() || this.getOriginByScriptSrc();
  }
}
