import { KswElementHandler } from './ksw-element-handler';
import { Api } from './interfaces/api/api';
import { InitialApi } from './interfaces/api/initial-api';

export class ApiHandler {
  public api: Api;

  public bootIsQueued: boolean;

  private kswElementHandler: KswElementHandler;

  constructor(
    private _kswElementHandler: KswElementHandler,
    private initialApi: InitialApi,
  ) {
    this.kswElementHandler = _kswElementHandler;
    this.api = this.createApi();
    const queue = this.getQueue(initialApi);
    this.bootIsQueued = this.isBootQueued(queue);
    this.processQueue(queue);
  }

  // initialApi: { q?: any[] }
  private createApi(): Api {
    const e: Record<string, any> = {
      boot: () => {
        this.kswElementHandler.boot();
      },
      shutdown: () => {
        this.kswElementHandler.shutdown();
      },
    };

    return (...args) => {
      const argLength = args.length;
      const arg = new Array(argLength);
      for (let i = 0; i < argLength; i += 1) {
        arg[i] = args[i];
      }
      const action: string = arg[0];
      if (action && e[action]) {
        return e[action].apply(undefined, arg.slice(1));
      }
      return undefined;
    };
  }

  private getQueue = (api: InitialApi): any[] => {
    return (api && api.q) || [];
  };

  private isBootQueued = (queue: any[]): boolean => {
    return queue.some((action) => {
      return action[0] === 'boot' || action[0] === 'shutdown';
    });
  };

  private processQueue = (queue: any[]) => {
    while (queue.length) {
      this.api.apply(undefined, queue.shift());
    }
  };
}
