import { ApiHandler } from './api-handler';
import { Target } from './interfaces/target';
import { KswElementHandler } from './ksw-element-handler';

const target: Target = {
  settings: 'OnlimKSWConfig',
  api: 'OnlimKSW',
};

const w: any = window;

const settings: any = w[target.settings];
settings.widgetName = 'ksw';

const kswElementHandler = new KswElementHandler(
  window,
  'onlim-ksw-anchor',
  target,
  settings,
);

kswElementHandler.setStyles();

const apiHandler = new ApiHandler(kswElementHandler, w[target.api]);

w[target.api] = apiHandler.api;

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
kswElementHandler.htmlContent = require('../../../dist/ksw/app/index.html');

// auto booter
if (settings.autoBoot !== false && !apiHandler.bootIsQueued) {
  kswElementHandler.boot();
}
